import React from "react"
import { graphql } from "gatsby"
import SEO from '../components/seo'
import Layout from '../components/layout'
import styles from './manual-article.module.scss'

export default function Template({ data,}) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout locale="ja">
      <SEO
        title={frontmatter.title}
        description={frontmatter.title}
        type="article"
        hrefLangs={["ja"]}
      />

      <main role="main" className={styles.main}>
        <section>
          <article className={styles.article}>
            <div className={styles.title}>
              <h1>{frontmatter.title}</h1>
              <time>{frontmatter.date}</time>
            </div>
            <div
              className={styles.content}
              dangerouslySetInnerHTML={{
                __html: html,
              }}
            ></div>
          </article>
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        slug
        title
      }
    }
  }
`